<template>
  <v-card
    class="px-2"
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        class="text-center"
      >
        <h1>
          Verificação do serviço MEU CLINIC
        </h1>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="text-justify"
      >
        <h3 class="font-weight-regular">
          1. Antes de começar, certifique-se de que esteja no SERVIDOR ou na MÁQUINA onde está instalado o MEU CLINIC, com a conta de
          usuário aberta.<br><br>
          2. Verifique se o ícone do tentacle está no System tray ao lado do relógio, conforme imagem abaixo.<br>
        </h3>
        <br>
        <v-img src="../../../public/manual/system-tray.jpeg" />
        <br>
        <h3 class="font-weight-regular">
          Primeiramente, verifique atualizacões da aplicação clicando
          com o botão direito do mouse no ícone do tentacle.
        </h3>
        <br>
        <v-img src="../../../public/manual/update.jpeg" />
        <p class="text-right">
          (1) *Opção 'Verificar atualização' no menu de contexto.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Sempre tente fechar e abrir novamente o MEU CLINIC, pois o Windows possui muitos problemas de instabilidade. Para fazer isso, clique no ícone do
          tentacle com o botão direito do mouse (botão de contexto) e clique na opção “Sair”.
        </h3>
        <br>
        <v-img src="../../../public/manual/sair.jpeg" />
        <p class="text-right">
          (2) *Opção “Sair” no menu de contexto, barra de tarefas, para finalizar o programa.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Abra novamente com dois clicks no ícone do tentacle na área de trabalho.<br>
        </h3>
        <br>
        <v-img src="../../../public/manual/desktop.jpeg" />
        <p class="text-right">
          (3) *Ícone tentacle na área de trabalho. Duplo clique no ícone tentacle para abrir
          novamente o programa.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Caso não localize este ícone na área de trabalho, procure por ele no menu
          de pesquisa do Windows e abra com dois cliques. <br>
        </h3>
        <br>
        <v-img src="../../../public/manual/searchbar.jpeg" />
        <p class="text-right">
          (4) *Localizar o botão de menu do windows, clicar, digitar na barra de pesquisa
          “Spitzer tentacle” e clicar no programa para abrir.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Por último, verifique se o ícone do tentacle ficou ao lado do relógio do windows na barra de
          tarefas conforme a imagem abaixo: <br>
        </h3>
        <br>
        <v-img src="../../../public/manual/system-tray.jpeg" />
        <p class="text-right">
          (5) *Ícone do tentacle ao lado do relógio do windows na barra de tarefas.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Pronto. O programa está aberto! <br>
        </h3>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <h1>
          Verificação do aplicativo NGROK
        </h1>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="text-justify"
      >
        <h3 class="font-weight-regular">
          1. Abrir o gerenciador de tarefas, via atalho ctrl+alt+del<br>
        </h3>
        <br>
        <v-img src="../../../public/manual/task-m.jpeg" />
        <p class="text-right">
          (1) *Menu com opção em destaque para abrir o gerenciador de tarefas.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Ou clicando com o botão direito (botão de contexto do mouse) no meio da
          barra de tarefas onde não há ícones. (A barra de tarefas geralmente é a barra do relógio e menu fica na
          parte inferior da interface do Windows; salvo algumas raras exceções, ela estará
          localizada em outro local da interface). Abaixo,
          segue como exemplo a imagem de como vai abrir o menu:
        </h3>
        <br>
        <v-img src="../../../public/manual/menu.jpeg" />
        <p class="text-right">
          (2) *Menu de contexto com opção em destaque para abrir o gerenciador de tarefas.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Uma janela como essa abaixo será aberta. Clique na aba
          “Serviços”. (Em alguns sistemas em inglês a opção da aba será “Services”).
        </h3>
        <br>
        <v-img src="../../../public/manual/task-m1.jpeg" />
        <p class="text-right">
          (3) *Gerenciador de tarefas com serviço NGROK em destaque.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Como podemos ver nesta imagem deve ser localizado o serviço NGROK,
          ele cria o vínculo da internet com o Spitzer tentacle.<br><br>
          Mesmo que ele esteja em execução você deve reiniciá-lo para evitar
          problemas com o windows.<br><br>
          Para isso, clique com o botão direito no serviço NGROK, e selecione a
          opção reiniciar que aparecerá no menu de contexto:<br>
        </h3>
        <br>
        <v-img src="../../../public/manual/task-m2.jpeg" />
        <p class="text-right">
          (4) *Menu após clique do botão direito do mouse com 'Reiniciar' em destaque.
        </p>
        <br>
        <h3 class="font-weight-regular">
          Agora o serviço NGROK e o Aplicativo MEU CLINIC deverão estar
          funcionando no sistema. Por favor clique em ATUALIZAR para verificar se o serviço está ativo.<br>
        </h3>
        <h3 class="font-weight-bold">
          <br>Fim.<br>
        </h3>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'Manual',
  }
</script>
